<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 입출고일 -->
          <c-datepicker
            :range="true"
            name="period"
            label="입출고일"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :afterIcon="
              [
                {
                  name: 'search',
                  click: true,
                  callbackName: 'searchSafetyGear',
                },
                {
                  name: 'close',
                  click: true,
                  callbackName: 'removeSafetyGear',
                },
              ]
            "
            :editable="editable"
            :readonly="true"
            label="보호구"
            name="safetyGearName"
            v-model="searchParam.safetyGearName"
            @searchSafetyGear="searchSafetyGear"
            @removeSafetyGear="removeSafetyGear"
          >
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            :comboItems="comboItems"
            itemText="codeName"
            itemValue="code"
            label="입/출고"
            name="typeFlag"
            v-model="searchParam.typeFlag"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="보호구 입/출고 현황"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'relatePrice'">
          <font v-if="props.row['typeName'] === '입고'">
            {{ setCost(props.row) }}원
          </font>
          <font v-else>
          </font>
        </template>
      </template>
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openNew" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { toThousandFilter } from '@/utils/filter';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-gear-receive-status',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'safetyGearId' },
          { index: 1, colName: 'safetyGearId' },
          // { index: 2, colName: 'safetyGearId' },
        ],
        columns: [
          {
            name: 'safetyGearName',
            field: 'safetyGearName',
            label: '보호구',
            style: 'width:200px',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'supplyVendorName',
          //   field: 'supplyVendorName',
          //   label: '공급업체',
          //   style: 'width:110px',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'typeName',
            field: 'typeName',
            label: '구분',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'relateDate',
            field: 'relateDate',
            label: '입/출고일',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'relatePrice',
            field: 'relatePrice',
            label: '단가',
            style: 'width:100px',
            align: 'right',
            type: 'custom',
            sortable: true,
          },
          {
            name: 'relateCount',
            field: 'relateCount',
            label: '입/출고수량',
            style: 'width:80px',
            align: 'right',
            sortable: true,
          },
          {
            name: 'unitName',
            field: 'unitName',
            label: '단위',
            align: 'center',
            style: 'width:70px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            style: 'width:350px',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        typeFlag: null,
        safetyGearId: '',
        safetyGearName: '',
      },
      comboItems: [
        { code: 'Y', codeName: '입고' },
        { code: 'N', codeName: '출고' },
      ],
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.sas.recepit.list.url;
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    openNew() {
      this.linkClick({giveDt:'',safetySuppliesGiveId:'',plantCd:''});
    },
    linkClick(row, col, index) {
      this.popupOptions.title = '보호구 입출고 현황 상세'; // 
      this.popupOptions.param = {
        safetyGearId: row.safetyGearId ? row.safetyGearId : '',
      };
      this.popupOptions.target = () => import(`${'./safetyGearReceiveStatusDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.width = '80%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    searchSafetyGear() {
      this.popupOptions.title = "보호구 선택"; // 보호구 선택
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () =>
        import(`${"./safetyGearPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSafetyGearPopup;
    },
    closeSafetyGearPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.searchParam.safetyGearId = data[0].safetyGearId;
        this.searchParam.safetyGearName = data[0].safetyGearName;
      }
    },
    removeSafetyGear() {
      this.searchParam.safetyGearId = '';
      this.searchParam.safetyGearName = '';
    },
    setCost(row) {
      return toThousandFilter(row.relatePrice)
    }
  },
};
</script>
